<template>
  <v-container>
    <v-row class="mx-1">
      <v-col cols="12" md="6">
        <h1 class="green-prosk">PROSK</h1>
      </v-col>
    </v-row>
    <v-row class="d-md-none d-block mx-1">
      <v-col cols="12" class="bg-light-grey">
        <v-row>
          <v-col cols="6" class="p-0">
            <strong>{{ $t("total") }}: </strong>
          </v-col>
          <v-col cols="6" class="p-0 text-right" v-if="totalCost">
            <money-format
              :value="totalCost"
              disabled
              :currency-code="currencyCode"
              :locale="$i18n.locale"
              :supplemental-precision="0"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mx-1">
      <v-col cols="12" md="6">
        <v-row>
          <v-expansion-panels v-model="panel" accordion mandatory>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t("payForm") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-3 pb-5">
                  <v-row>
                    <v-col cols="12" align="center" justify="center">
                      <v-avatar>
                        <img
                          v-if="!this.currentUser"
                          src="@/assets/images/page-img/avatar.png"
                          alt="user"
                          class="avatar-60"
                        />
                        <img
                          v-if="this.currentUser && !this.currentUser.profile_img"
                          src="@/assets/images/page-img/avatar.png"
                          alt="user"
                          class="avatar-60"
                        />
                        <img
                          v-if="this.currentUser && this.currentUser.profile_img"
                          :src="this.currentUser.profile_img"
                          class="rounded-circle avatar-60"
                          alt="user"
                        />
                      </v-avatar>
                    </v-col>
                  </v-row>
                  <v-row>
                    <input
                      type="hidden"
                      id="mercado-pago-public-key"
                      th:value="${publicKey}"
                    />
                    <form id="form-checkout">
                      <v-row>
                        <!--<input id="form-checkout__cardholderName" name="cardholderName" type="text" class="form-control"/>-->
                        <v-col :cols="!this.currentUser ? 6 : 12">
                          <v-text-field
                            id="form-checkout__cardholderName"
                            name="cardholderName"
                            type="text"
                            outlined
                            color="#59D79D"
                            :label="$t('name')"
                            :hide-details="nameError.length === 0"
                            :error-count="nameError.length"
                            :error-messages="nameError"
                            v-model="name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="!this.currentUser">
                          <v-text-field
                            outlined
                            :label="$t('lastName')"
                            color="#59D79D"
                            required
                            :hide-details="lastNameError.length === 0"
                            :error-count="lastNameError.length"
                            :error-messages="lastNameError"
                            v-model="lastName"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="pr-0" v-if="!this.currentUser">
                          <v-autocomplete
                            :items="phoneCodes"
                            :label="$t('countryCode')"
                            prefix="+"
                            outlined
                            :hide-details="phoneCodeError.length === 0"
                            :error-count="phoneCodeError.length"
                            :error-messages="phoneCodeError"
                            item-text="code"
                            item-value="code"
                            return-object
                            v-model="phoneInternationalInfos"
                            color="#59D79D"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  <flag :iso="data.item.country" />
                                  <span class="pl-2">
                                    {{ data.item.code }}</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="5" v-if="!this.currentUser" class="px-0">
                          <v-text-field
                            :label="$t('phoneNumber')"
                            outlined
                            color="#59D79D"
                            @keypress="onlyNumber"
                            :counter="phoneInternationalInfos.phoneMask.length"
                            :maxlength="phoneInternationalInfos.phoneMask.length"
                            name="cardPhone"
                            id="form-checkout__cardPhone"
                            :hide-details="phoneError.length === 0"
                            :error-count="phoneError.length"
                            :error-messages="phoneError"
                            v-model="phone"
                            v-mask="phoneInternationalInfos.phoneMask"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="!this.currentUser">
                          <v-text-field
                            :label="$t('userName')"
                            outlined
                            required
                            color="#59D79D"
                            :hide-details="userNameError.length === 0"
                            :error-count="userNameError.length"
                            :error-messages="userNameError"
                            v-model="userName"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="!this.currentUser">
                          <v-text-field
                            :label="$t('password')"
                            required
                            outlined
                            color="#59D79D"
                            :hide-details="errors.length === 0"
                            :error-count="errors.length"
                            :error-messages="errors"
                            type="password"
                            v-model="password"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="!this.currentUser">
                          <v-text-field
                            :label="$t('confirmPassword')"
                            required
                            color="#59D79D"
                            outlined
                            :hide-details="confirmError.length === 0"
                            :error-count="confirmError.length"
                            :error-messages="confirmError"
                            type="password"
                            v-model="confirmPassword"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="!this.currentUser">
                          <v-text-field
                            :label="$t('birthDate')"
                            outlined
                            type="date"
                            color="#59D79D"
                            required
                            :hide-details="birthDateError.length === 0"
                            :error-count="birthDateError.length"
                            :error-messages="birthDateError"
                            v-model="birthDate"
                          ></v-text-field>
                        </v-col>
                        <!--<input id="form-checkout__cardholderEmail" name="cardholderEmail" type="email" class="form-control"/>-->
                        <v-col :cols="!this.currentUser ? 6 : 12">
                          <v-text-field
                            id="form-checkout__cardholderEmail"
                            name="cardholderEmail"
                            type="email"
                            :label="$t('email')"
                            outlined
                            color="#59D79D"
                            :hide-details="emailError.length === 0"
                            :error-count="emailError.length"
                            :error-messages="emailError"
                            v-model="email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="!this.currentUser">
                          <v-text-field
                            :label="$t('domicile')"
                            v-model="line_1"
                            color="#59D79D"
                            outlined
                            :hide-details="domicileErrors.length === 0"
                            :error-count="domicileErrors.length"
                            :error-messages="domicileErrors"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="!this.currentUser">
                          <v-text-field
                            :label="$t('city')"
                            v-model="city"
                            color="#59D79D"
                            outlined
                            :hide-details="cityErrors.length === 0"
                            :error-count="cityErrors.length"
                            :error-messages="cityErrors"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="!this.currentUser">
                          <v-text-field
                            :label="$t('postalCode')"
                            v-model="postal_code"
                            color="#59D79D"
                            type="number"
                            outlined
                            @keypress="onlyNumber"
                            :hide-details="postalCodeErrors.length === 0"
                            :error-count="postalCodeErrors.length"
                            :error-messages="postalCodeErrors"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="!this.currentUser">
                          <v-text-field
                            :label="$t('state')"
                            v-model="state"
                            outlined
                            color="#59D79D"
                            :hide-details="stateErrors.length === 0"
                            :error-count="stateErrors.length"
                            :error-messages="stateErrors"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="!this.currentUser">
                          <v-text-field
                            :label="$t('country')"
                            v-model="country"
                            outlined
                            color="#59D79D"
                            :hide-details="countryErrors.length === 0"
                            :error-count="countryErrors.length"
                            :error-messages="countryErrors"
                          ></v-text-field>
                        </v-col>
                        <!--<input id="form-checkout__cardNumber" name="cardNumber" type="text" class="form-control"/>-->
                        <v-col cols="12">
                          <v-text-field
                            :label="$t('creditCard')"
                            outlined
                            color="#59D79D"
                            @keypress="onlyNumber"
                            id="form-checkout__cardNumber"
                            type="text"
                            name="cardNumber"
                            :hide-details="creditCardError.length === 0"
                            :error-count="creditCardError.length"
                            :error-messages="creditCardError"
                            v-model="creditCard"
                          ></v-text-field>
                        </v-col>
                        <!--<input id="form-checkout__cardExpirationMonth" name="cardExpirationMonth" type="text" class="form-control"/>-->
                        <v-col cols="6">
                          <v-text-field
                            :label="$t('expirationMonth')"
                            outlined
                            color="#59D79D"
                            @keypress="onlyNumber"
                            :maxlength="max"
                            id="form-checkout__cardExpirationMonth"
                            name="cardExpirationMonth"
                            type="text"
                            :hide-details="expirationMonthError.length === 0"
                            :error-count="expirationMonthError.length"
                            :error-messages="expirationMonthError"
                            v-model="expirationMonth"
                          ></v-text-field>
                        </v-col>
                        <!--<input id="form-checkout__cardExpirationYear" name="cardExpirationYear" type="text" class="form-control"/>-->
                        <v-col cols="6">
                          <v-text-field
                            :label="$t('expirationYear')"
                            outlined
                            color="#59D79D"
                            @keypress="onlyNumber"
                            :maxlength="max"
                            id="form-checkout__cardExpirationYear"
                            name="cardExpirationYear"
                            type="text"
                            :hide-details="expirationYearError.length === 0"
                            :error-count="expirationYearError.length"
                            :error-messages="expirationYearError"
                            v-model="expirationYear"
                          ></v-text-field>
                        </v-col>
                        <!--<input id="form-checkout__securityCode" name="securityCode" type="text" class="form-control"/>-->
                        <v-col :cols="!job ? 6 : 12">
                          <v-text-field
                            :label="$t('securityCode')"
                            outlined
                            color="#59D79D"
                            id="form-checkout__securityCode"
                            name="securityCode"
                            type="text"
                            maxlength="3"
                            :hide-details="securityCodeError.length === 0"
                            :error-count="securityCodeError.length"
                            :error-messages="securityCodeError"
                            v-model="securityCode"
                          ></v-text-field>
                        </v-col>
                        <!--<select id="form-checkout__installments" name="installments" type="text" class="form-control"></select>-->
                        <v-col cols="6" class="d-none">
                          <select
                            id="form-checkout__installments"
                            name="installments"
                            class="select-css"
                          ></select>
                        </v-col>
                        <v-col cols="6" v-if="!job">
                          <v-autocomplete
                            :label="$t('selectPaymentInstallments')"
                            :items="paymentsInstallment"
                            :search-input.sync="paymentsInstallmentInput"
                            @change="paymentsInstallmentInput = ''"
                            v-model="paymentInstallment"
                            color="#59D79D"
                            outlined
                            :hide-details="paymentInstallmentsError.length === 0"
                            :error-count="paymentInstallmentsError.length"
                            :error-messages="paymentInstallmentsError"
                            item-color="#59D79D"
                          ></v-autocomplete>
                        </v-col>
                        <!--<select id="form-checkout__identificationType" name="identificationType" class="form-control"></select>-->
                        <v-col cols="6">
                          <select
                            id="form-checkout__identificationType"
                            name="identificationType"
                            class="select-css"
                          ></select>
                        </v-col>
                        <!--<input id="form-checkout__identificationNumber" name="docNumber" type="text" class="form-control"/>-->
                        <v-col cols="6">
                          <v-text-field
                            :label="$t('documentNumber')"
                            outlined
                            color="#59D79D"
                            id="form-checkout__identificationNumber"
                            name="identificationNumber"
                            type="text"
                            :hide-details="documentNumberError.length === 0"
                            :error-count="documentNumberError.length"
                            :error-messages="documentNumberError"
                            v-model="identificationNumber"
                          ></v-text-field>
                        </v-col>
                        <!--<select id="form-checkout__issuer" name="issuer" class="form-control"></select>-->
                        <select
                          id="form-checkout__issuer"
                          name="issuer"
                          class="d-none"
                        ></select>
                        <input type="hidden" id="amount" />
                        <input type="hidden" id="description" />
                        <v-col cols="12">
                          <v-btn
                            :loading="load"
                            @click="
                              currentUser
                                ? nextStep()
                                : registerNewUser()
                            "
                            color="#59D79D"
                            class="white--text"
                            block
                          >
                            {{ $t("next") }}
                          </v-btn>
                          <button
                            id="form-checkout__submit"
                            type="submit"
                            class="d-none"
                          ></button>
                        </v-col>
                      </v-row>
                    </form>
                  </v-row>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :disabled="cardDataDisabled">
              <v-expansion-panel-header>
                {{ $t("contactInformation") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pb-5">
                  <v-row>
                    <v-col
                      cols="12"
                      align="center"
                      justify="center"
                      class="mb-3"
                    >
                      <v-avatar>
                        <img
                          v-if="!this.currentUser"
                          src="@/assets/images/page-img/avatar.png"
                          alt="user"
                          class="avatar-60"
                        />
                        <img
                          v-if="
                            this.currentUser && !this.currentUser.profile_img
                          "
                          src="@/assets/images/page-img/avatar.png"
                          alt="user"
                          class="avatar-60"
                        />
                        <img
                          v-if="
                            this.currentUser && this.currentUser.profile_img
                          "
                          :src="this.currentUser.profile_img"
                          class="rounded-circle avatar-60"
                          alt="user"
                        />
                      </v-avatar>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-row>
                      <v-col cols="12" v-if="!this.currentUser">
                        <alert :message="$t('dontForgetCheckYourEmail')"/>
                      </v-col>
                      <v-col cols="3" v-if="this.currentUser" class="pr-0">
                        <v-autocomplete
                          :items="phoneCodes"
                          :label="$t('countryCode')"
                          prefix="+"
                          outlined
                          :hide-details="phoneCodeError.length === 0"
                          :error-count="phoneCodeError.length"
                          :error-messages="phoneCodeError"
                          item-text="code"
                          item-value="code"
                          return-object
                          v-model="phoneInternationalInfos"
                          color="#59D79D"
                        >
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-subtitle>
                                <flag :iso="data.item.country" />
                                <span class="pl-2"> {{ data.item.code }}</span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="9" v-if="this.currentUser">
                        <v-text-field
                          :label="$t('phoneNumber')"
                          outlined
                          color="#59D79D"
                          name="cardPhone"
                          @keypress="onlyNumber"
                          id="form-checkout__cardPhone"
                          :counter="phoneInternationalInfos.phoneMask.length"
                          :maxlength="phoneInternationalInfos.phoneMask.length"
                          :hide-details="phoneError.length === 0"
                          :error-count="phoneError.length"
                          :error-messages="phoneError"
                          v-model="phone"
                          v-mask="phoneInternationalInfos.phoneMask"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="this.currentUser">
                        <v-row>
                          <v-col cols="6">
                            {{ $t("myAddresses") }}
                          </v-col>
                          <v-col cols="6">
                            <user-addresses-dialog
                              :item="editedItem"
                              :on-close="onAddClose"
                              :new-item="newItem"
                              :show-add="showAdd"
                            />
                          </v-col>
                          <div v-if="this.currentUser && this.currentUser.addresses">
                            <v-col cols="12" v-for="address in currentUser.addresses" :key="address.id">
                              <v-card>
                                <v-card-text class="py-2">
                                  <strong>{{ $t("domicile") }}</strong><br><br>
                                  {{ address.line_1 }}<br>
                                  {{ address.neighborhood }}<br>
                                  {{ address.city.name }}<br>
                                  {{ address.state ? address.state : ''  }} {{ address.postal_code }}<br>
                                  <strong v-if="!address.is_default">{{ $t("isDefault") }}</strong>
                                </v-card-text>
                                <v-card-actions>
                                  <v-col
                                    cols="6"
                                    class="p-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-btn
                                      icon
                                      color="secondary"
                                      @click="editItem(address)"
                                    >
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="6" class="p-0">
                                    <v-btn
                                      @click="selectAddress(address)"
                                      block
                                      color="#59D79D"
                                      class="white--text float-right"
                                    >
                                      <v-icon v-if="address.selected"
                                        >mdi-check</v-icon
                                      >
                                      <v-icon v-else>mdi-gesture-tap</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-card-actions>
                              </v-card>
                            </v-col>
                          </div>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          @click="payOrder"
                          :loading="loading"
                          :disabled="!canContinue"
                          color="#59D79D"
                          class="white--text"
                          block
                        >
                          {{ $t("pay") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-col>
      <v-col md="6" class="d-none d-md-block p-3 bg-prosk-secondary" v-if="job || subscription">
        <v-row>
          <v-col cols="3">
            <strong class="green-prosk">{{ $t("description") }}: </strong>
          </v-col>
          <v-col cols="9">
            <span>{{ job ? job.description : subscription.description }}</span>
          </v-col>
          <v-col cols="3">
            <strong class="green-prosk">{{ $t("startDate") }}: </strong>
          </v-col>
          <v-col cols="9">
           <span> {{
              getDateFormat(
                subscription
                  ? subscription.created_at
                  : job.start_date
              )
             }}</span>
          </v-col>
          <v-col cols="3">
            <strong class="green-prosk">{{ subscription ? $t("name") : $t("expire") }}:</strong>
          </v-col>
          <v-col cols="9">
            <span> {{
              subscription
                ? subscription.name
                : job.expire
              }}</span>
          </v-col>
          <v-col cols="3">
            <strong class="green-prosk">{{ $t("total") }}: </strong>
          </v-col>
          <v-col cols="9">
            <money-format
              v-if="totalCost"
              :value="totalCost"
              disabled
              :currency-code="currencyCode"
              :locale="$i18n.locale"
              :supplemental-precision="0"
            />
            <p id="total-cost" class="d-none">{{ totalCost }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import MoneyFormat from 'vue-money-format';
import axios from 'axios';
import moment from 'moment';
import { mask } from 'vue-the-mask';
import { PhoneNumberUtil } from 'google-libphonenumber';
import UserAddressesDialog from '@/components/socialvue/dialogs/UserAddressesDialog.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { getPhoneCodes, validateMonth, typeOnlyNumber, removeMaskFromPhoneNumber, unhashData } from '@/misc/utils';
import { JOB_STATUS_NEW, AES_SECRET } from '@/misc/constants';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import userDataMixin from '@/mixins/userDataMixin';

const phoneCodes = getPhoneCodes();
const defaultPhoneInternationalInfos = phoneCodes[1];

export default {
  name: 'Checkout',
  directives: { mask },
  components: { Alert, UserAddressesDialog, MoneyFormat },
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      currencyCode: '',
      job: null,
      subscription: null,
      mpPublicKey: '',
      totalCost: 0,
      searchPhoneCodesInput: '',
      phoneCodes: phoneCodes,
      phoneInternationalInfos: defaultPhoneInternationalInfos,
      phoneCodeError: [],
      lastNameError: [],
      userNameError: [],
      birthDateError: [],
      documentTypeError: [],
      paymentMethodError: [],
      documentNumberError: [],
      emailError: [],
      expirationYearError: [],
      expirationMonthError: [],
      addressError: [],
      postalCodeError: [],
      phoneError: [],
      errors: [],
      creditCardError: [],
      postalCodeErrors: [],
      cityErrors: [],
      stateErrors: [],
      nameError: [],
      domicileErrors: [],
      countryErrors: [],
      confirmError: [],
      errorFormMounted: [],
      securityCodeError: [],
      paymentInstallmentsError: [],
      paymentsInstallment: [],
      paymentsInstallmentItems: [
        { name: this.$t('monthly'), type: 1 },
        { name: this.$t('halfYearly'), type: 6 },
        { name: this.$t('annual'), type: 12 }
      ],
      lastName: null,
      paymentInstallment: 1,
      birthDate: null,
      userName: null,
      postal_code: null,
      state: null,
      line_1: null,
      city: null,
      country: null,
      loading: false,
      load: false,
      panel: 0,
      max: 2,
      confirmPassword: null,
      cardDataDisabled: true,
      showAdd: false,
      editedItem: {},
      password: '',
      company: null,
      tab: 0,
      canContinue: false,
      paymentsInstallmentInput: '',
      addressSelected: {},
      cardForm: {},
      addressId: null,
      mercadoPago: {},
      name: null,
      identificationNumber: null,
      email: null,
      postalCode: null,
      phone: null,
      creditCard: null,
      expirationYear: null,
      expirationMonth: null,
      proskerDataVerified: false,
      securityCode: null,
      banner_img: null,
      profile_img: null,
      currentYear: null,
      paymentsMethods: [],
      userId: null,
      mp: null,
      alreadySendPaymentInfo: false
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  created () {
    this.currencyCode = process.env.VUE_APP_LOCATION.includes('br') ? 'BRL' : 'UYU';
    this.setImportedConstants();
    if (this.currentUser) {
      if (this.$route.params.subscriptionId && (this.currentUser.active_subscription.find(item => item.name === 'Premium' || item.name === 'Free Trial') || this.currentUser.paused_subscription.find(item => item.name === 'Premium'))) {
        this.$router.push({ name: 'social.user-subscription' });
      }
      this.preloadUserInformation();
    }

    if (!this.currentUser && this.$route.params.serviceId) {
      this.$router.push({ name: 'auth1.sign-in1' });
    }

    if (this.$route.params.serviceId) {
      this.getJob(this.$route.params.serviceId);
    }

    if (this.$route.params.subscriptionId) {
      const subscriptionId = unhashData(this.$route.params.subscriptionId, this.$options.AES_SECRET);
      this.getSubscription(subscriptionId);
    }

    this.setInstallments();
  },
  watch: {
    'creditCard': function () {
      if (!this.alreadySendPaymentInfo && this.creditCard) {
      this.trackUserData('add_payment_info', {
          ecommerce: {
            currency: this.currencyCode,
            items: [
              {
                item_id: this.$route.params.serviceId,
                item_name: this.job ? this.job.title : this.subscription.title,
                price: this.totalCost,
                quantity: 1
              }
            ]
          }
    });
    this.alreadySendPaymentInfo = true;
  }
    }
  },
  methods: {
    sendDataLayerPayment () {
      this.trackUserData('purchase', {
          ecommerce: {
            currency: this.currencyCode,
            items: [
              {
                item_id: this.$route.params.serviceId,
                item_name: this.job ? this.job.title : this.subscription.title,
                price: this.totalCost,
                quantity: 1
              }
            ]
          }
    });
    },
    insertMPScript () {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://sdk.mercadopago.com/js/v2';
      script.addEventListener('load', this.loadCardForm);
      document.body.appendChild(script);
    },
    setImportedConstants () {
      this.$options.JOB_STATUS_NEW = JOB_STATUS_NEW;
      this.$options.AES_SECRET = AES_SECRET;
    },
    isPayableJob () {
      return (this.job.client_user_id === this.currentUser.id) && (this.job.status === this.$options.JOB_STATUS_NEW) && this.job.prosker_user.mp_public_key;
    },
    getJob (id) {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${id}`;
      axios
      .get(url)
      .then(({ data }) => {
        this.job = data.data;
        this.loading = false;
        if (!this.isPayableJob()) {
          this.$router.push({ path: '/servicios-contratados' });
        } else {
          this.totalCost = this.job.price;
          this.mpPublicKey = this.job.prosker_user.mp_public_key;
          this.insertMPScript();
          this.trackUserData('begin_checkout', {
          ecommerce: {
            currency: this.currencyCode,
            items: [
              {
                item_id: id,
                item_name: this.job.title,
                price: this.totalCost,
                quantity: 1
              }
            ]
          }
    });
        }
      })
      .catch((error) => {
        this.loading = false;
        this.showError(error);
        this.$router.push({ path: '/servicios-contratados' });
      });
    },
    getSubscription (id) {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/subscriptions/${id}`;
      axios
      .get(url)
      .then(({ data }) => {
        this.subscription = data.data;
        this.totalCost = this.subscription.cost;
        this.paymentInstallment = 1;
        this.mpPublicKey = process.env.VUE_APP_MP_APP_SUBSCRIPTIONS_PUBLIC_KEY;
        this.insertMPScript();
        this.loading = false;
        this.trackUserData('begin_checkout', {
          ecommerce: {
            currency: this.currencyCode,
            items: [
              {
                item_id: id,
                item_name: this.subscription.name,
                price: this.totalCost,
                quantity: 1
              }
            ]
          }
    });
      })
      .catch((error) => {
        this.loading = false;
        this.showError(error);
      });
    },
    validateYear (year) {
      this.currentYear = moment().format('YY');
      return this.currentYear < year;
    },
    onlyNumber ($event) {
      return typeOnlyNumber($event);
    },
    setInstallments () {
      this.paymentsInstallment.splice(0, this.paymentsInstallment.length);
      this.paymentsInstallmentItems.forEach((type) => {
        type.value = type.type;
        type.text = type.name;
        this.paymentsInstallment.push(type);
      });
    },
    resetErrors () {
      this.postalCodeErrors = [];
      this.phoneCodeError = [];
      this.cityErrors = [];
      this.stateErrors = [];
      this.countryErrors = [];
      this.domicileErrors = [];
      this.confirmError = [];
      this.userNameError = [];
      this.lastNameError = [];
      this.nameError = [];
      this.paymentInstallmentsError = [];
      this.errors = [];
      this.documentTypeError = [];
      this.birthDateError = [];
      this.documentNumberError = [];
      this.emailError = [];
      this.paymentMethodError = [];
      this.addressError = [];
      this.expirationMonthError = [];
      this.expirationYearError = [];
      this.postalCodeError = [];
      this.phoneError = [];
      this.creditCardError = [];
      this.securityCodeError = [];
    },
    editItem (item) {
      this.editedItem = item;
      this.editedItem.isEdit = true;
      this.showAdd = true;
    },
    validateMonths (month) {
      return validateMonth(month);
    },
    newItem () {
      this.showAdd = true;
      this.editedItem.isEdit = false;
    },
    getUserAddresses () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}`;
      axios
      .get(url)
      .then(({ data }) => {
        this.userAddresses = data.data.addresses.sort((a, b) => {
          if (a.is_default > b.is_default) {
            return 1;
          }
          if (a.is_default < b.is_default) {
            return -1;
          }
          return 0;
        });
      });
    },
    onAddClose () {
      this.getUserAddresses();
      this.editedItem = {};
      this.showAdd = false;
      this.editedItem.isEdit = false;
    },
    selectAddress (address) {
      this.currentUser.addresses = this.currentUser.addresses.map((address) => {
        address.selected = false;
        return address;
      });

      address.selected = true;
      this.addressSelected = address;
      this.canContinue = true;
      this.trackUserData('add_shipping_info', {
          ecommerce: {
            currency: this.currencyCode,
            items: [
              {
                item_id: this.$route.params.serviceId,
                item_name: this.job ? this.job.title : this.subscription.title,
                price: this.totalCost,
                quantity: 1
              }
            ]
          }
    });
    },
    verifyNewUserData () {
      this.resetErrors();
      const userNameValidation =
        /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
      const passwordValidation =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_.;,\\])[A-Za-z\d@$!%*#?&\-_.;,\\]{8,}$/;
      if (!this.name) {
        this.nameError.push(this.$t('fieldNameRequired'));
      }
      if (!this.email) {
        this.emailError.push(this.$t('fieldEmailRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.emailError.push(this.$t('enterValidEmail'));
      }
      if (
        !this.identificationNumber ||
        (this.identificationNumber && this.identificationNumber.length < 8)
      ) {
        this.documentNumberError.push(this.$t('fieldDocumentNumberRequired'));
      }
      if (
        !this.creditCard ||
        (this.creditCard && this.creditCard.length < 15)
      ) {
        this.creditCardError.push(this.$t('fieldCreditCardRequired'));
      }
      if (!this.paymentInstallment) {
        this.paymentInstallmentsError.push(
          this.$t('fieldInstallmentsRequired')
        );
      }
      if (!this.expirationMonth) {
        this.expirationMonthError.push(this.$t('fieldExpirationDateRequired'));
      }
      if (!this.validateMonths(this.expirationMonth)) {
        this.expirationMonthError.push(
          this.$t('fieldExpirationDateNotCorrect')
        );
      }
      if (!this.expirationYear || !this.validateYear(this.expirationYear)) {
        this.expirationYearError.push(this.$t('fieldExpirationDateRequired'));
      }
      if (!this.securityCode) {
        this.securityCodeError.push(this.$t('fieldSecurityCodeRequired'));
      }
      if (!this.password) {
        this.errors.push(this.$t('passwordRequired'));
      }
      if (this.password && !this.password.match(passwordValidation)) {
        this.errors.push(this.$t('invalidPassword'));
      }
      if (this.password !== this.confirmPassword) {
        this.confirmError.push(this.$t('passwordsMustMatch'));
      }
      if (!this.birthDate) {
        this.birthDateError.push(this.$t('fieldBirthDateRequired'));
      }
      if (!this.lastName) {
        this.lastNameError.push(this.$t('fieldLastNameRequired'));
      }
      if (!this.phone) {
        this.phoneError.push(this.$t('fieldPhoneNumberRequired'));
      }
      if (!this.phoneInternationalInfos.code) {
        this.phoneCodeError.push(this.$t('fieldCountryCodeRequired'));
      }
      if (this.phone && this.phone.length < 9) {
        this.phoneError.push(this.$t('fieldPhoneNumberAtLeast9Characters'));
      }
      if (!this.validatePhone(this.phone, this.phoneInternationalInfos)) {
        this.phoneError.push(this.$t('enterValidPhone'));
      }
      if (!this.userName) {
        this.userNameError.push(this.$t('invalidUser'));
      }
      if (this.userName && !this.userName.match(userNameValidation)) {
        this.userNameError.push(this.$t('invalidUser'));
      }
      if (!this.line_1) {
        this.domicileErrors.push(this.$t('fieldDomicileRequired'));
      }
      if (!this.state) {
        this.stateErrors.push(this.$t('fieldStateRequired'));
      }
      if (!this.postal_code) {
        this.postalCodeErrors.push(this.$t('fieldPostalCodeRequired'));
      }
      if (!this.city) {
        this.cityErrors.push(this.$t('fieldCityRequired'));
      }
      if (!this.country) {
        this.countryErrors.push(this.$t('fieldCountryRequired'));
      }

      return (
        this.name &&
        this.email &&
        this.validEmail(this.email) &&
        this.identificationNumber &&
        this.creditCard &&
        this.expirationMonth &&
        this.expirationYear &&
        this.securityCode &&
        this.paymentInstallment &&
        this.lastName &&
        this.phone &&
        this.validatePhone(this.phone, this.phoneInternationalInfos) &&
        this.userName &&
        this.userName.match(userNameValidation) &&
        this.password &&
        this.password.match(passwordValidation) &&
        this.birthDate &&
        this.phoneInternationalInfos.code &&
        this.password === this.confirmPassword &&
        this.country &&
        this.city &&
        this.postal_code &&
        this.state &&
        this.line_1 &&
        this.validateMonths(this.expirationMonth) &&
        this.phone.length > 9 &&
        this.creditCard.length > 14 &&
        this.identificationNumber.length > 7 &&
        this.validateYear(this.expirationYear)
      );
    },
    registerNewUser () {
      if (this.verifyNewUserData()) {
        this.load = true;
        const url = `${process.env.VUE_APP_BASE_URL}/api/register`;
        const data = {
          first_name: this.name,
          last_name: this.lastName,
          email: this.email,
          username: this.userName,
          birth_date: this.birthDate,
          phone_number: removeMaskFromPhoneNumber(this.phone),
          phone_code: this.phoneInternationalInfos.code,
          type_user: 10,
          company: this.company,
          password: this.password,
          password_confirmation: this.confirmPassword,
          banner_img: this.banner_img,
          profile_img: this.profile_img
        };

        axios
          .post(url, data)
          .then((response) => {
            this.userId = response.data.user.id;
            const addressUrl = `${process.env.VUE_APP_BASE_URL}/api/addresses`;
            const addressData = {
              user_id: this.userId,
              postal_code: this.postal_code,
              state: this.state,
              line_1: this.line_1,
              city: this.city,
              country: this.country,
              is_default: false
            };
            axios
              .post(addressUrl, addressData)
              .then((res) => {
                this.addressId = res.data.data.id;
              })
              .catch((error) => {
                this.loading = false;
                this.showError(error);
              });
            this.load = false;
            this.cardDataDisabled = false;
            this.panel = 1;
            this.canContinue = true;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
    },
    getDateFormat (date) {
      const originalDate = new Date(date);
      return moment(moment.utc(originalDate)).format('DD-MM-YYYY');
    },
    loadCardForm () {
      this.mercadopago = new window.MercadoPago(this.mpPublicKey, {
        locale: this.$i18n.locale
      });
      this.cardForm = this.mercadopago.cardForm({
        amount: document.getElementById('total-cost').innerHTML,
        autoMount: true,
        form: {
          id: 'form-checkout',
          cardholderName: {
            id: 'form-checkout__cardholderName'
          },
          cardholderEmail: {
            id: 'form-checkout__cardholderEmail'
          },
          cardNumber: {
            id: 'form-checkout__cardNumber'
          },
          cardExpirationMonth: {
            id: 'form-checkout__cardExpirationMonth'
          },
          cardExpirationYear: {
            id: 'form-checkout__cardExpirationYear'
          },
          securityCode: {
            id: 'form-checkout__securityCode'
          },
          identificationType: {
            id: 'form-checkout__identificationType'
          },
          installments: {
            id: 'form-checkout__installments'
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber'
          },
          issuer: {
            id: 'form-checkout__issuer'
          }
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error) {
              this.errorFormMounted.push(error);
            }
          },
          onError: (errors) => {
            errors.map((error) => {
              if (error.message.includes('cardNumber')) {
                this.creditCardError.push(this.$t('fieldCreditCardRequired'));
              }
              if (error.message.includes('cardExpirationMonth')) {
                this.expirationMonthError.push(
                  this.$t('fieldExpirationMonthRequired')
                );
              }
              if (error.message.includes('cardExpirationYear')) {
                this.expirationYearError.push(
                  this.$t('fieldExpirationYearRequired')
                );
              }
            });
          },
          onSubmit: (event) => {
            event.preventDefault();
          }
        }
      });
    },
    preloadUserInformation () {
      if (this.currentUser.first_name && this.currentUser.last_name) {
        this.name = `${this.currentUser.first_name} ${this.currentUser.last_name}`;
      }

      if (this.currentUser.email) {
        this.email = this.currentUser.email;
      }
      if (this.currentUser.phone_number) {
        this.phone = this.currentUser.phone_number;
      }
      if (this.currentUser.phone_code) {
        const foundedInternationalInfos = getPhoneCodes().find((phoneCode) => phoneCode.code === parseInt(this.currentUser.phone_code));
        this.phoneInternationalInfos = foundedInternationalInfos || defaultPhoneInternationalInfos;
      } else {
        this.phoneInternationalInfos = defaultPhoneInternationalInfos;
      }
    },
    validEmail (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validatePhone (phone, phoneInternationalInfos) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      return phoneUtil.isValidNumberForRegion(phoneUtil.parse(removeMaskFromPhoneNumber(phone), phoneInternationalInfos.country), phoneInternationalInfos.country)
    },
    verifyCardData () {
      this.resetErrors();
      if (!this.name) {
        this.nameError.push(this.$t('fieldNameRequired'));
      }
      if (!this.email) {
        this.emailError.push(this.$t('fieldEmailRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.emailError.push(this.$t('enterValidEmail'));
      }
      if (
        !this.creditCard ||
        (this.creditCard && this.creditCard.length < 15)
      ) {
        this.creditCardError.push(this.$t('fieldCreditCardRequired'));
      }
      if (!this.paymentInstallment) {
        this.paymentInstallmentsError.push(
          this.$t('fieldInstallmentsRequired')
        );
      }
      if (!this.expirationMonth) {
        this.expirationMonthError.push(this.$t('fieldExpirationDateRequired'));
      }
      if (!this.validateMonths(this.expirationMonth)) {
        this.expirationMonthError.push(
          this.$t('fieldExpirationDateNotCorrect')
        );
      }
      if (!this.securityCode) {
        this.securityCodeError.push(this.$t('fieldSecurityCodeRequired'));
      }
      if (!this.expirationYear || !this.validateYear(this.expirationYear)) {
        this.expirationYearError.push(this.$t('fieldExpirationDateRequired'));
      }
      if (
        !this.identificationNumber ||
        (this.identificationNumber && this.identificationNumber.length < 8)
      ) {
        this.documentNumberError.push(this.$t('fieldDocumentNumberRequired'));
      }
      return (
        this.name &&
        this.email &&
        this.validEmail(this.email) &&
        this.identificationNumber &&
        this.creditCard &&
        this.expirationMonth &&
        this.expirationYear &&
        this.securityCode &&
        this.paymentInstallment &&
        this.validateMonths(this.expirationMonth) &&
        this.creditCard.length > 14 &&
        this.identificationNumber.length > 7 &&
        this.validateYear(this.expirationYear)
      );
    },
    verifyUserData () {
      if (!this.phone) {
        this.phoneError.push(this.$t('fieldPhoneNumberRequired'));
      }
      if (!this.phoneInternationalInfos.code) {
        this.phoneCodeError.push(this.$t('fieldCountryCodeRequired'));
      }
      if (!this.validatePhone(this.phone, this.phoneInternationalInfos)) {
        this.phoneError.push(this.$t('enterValidPhone'));
      }
      return (
        this.phone &&
        this.validatePhone(this.phone, this.phoneInternationalInfos) &&
        this.phoneInternationalInfos.code
      );
    },
    nextStep () {
      if (this.verifyCardData()) {
        document.getElementById('form-checkout__submit').click();
        this.cardDataDisabled = false;
        this.panel = 1;
      }
    },
    getInvoiceCommonPayloadData () {
      return {
        client_phone_number: removeMaskFromPhoneNumber(this.phone),
        phone_code: this.phoneInternationalInfos.code,
        documentType: this.cardForm.getCardFormData().identificationType,
        document: this.cardForm.getCardFormData().identificationNumber,
        cardToken: this.cardForm.getCardFormData().token,
        amount: this.cardForm.getCardFormData().amount,
        installments: 1,
        payment_method_id: this.cardForm.getCardFormData().paymentMethodId
      };
    },
    paySubscription () {
      let data = this.getInvoiceCommonPayloadData();
      data.user_id = this.currentUser ? this.currentUser.id : this.userId;
      data.address_id = this.currentUser ? this.addressSelected.id : this.addressId;
      data.subscription_id = this.subscription.id;
      data.period = this.paymentInstallment;
      this.sendInvoiceRequest(
        `${process.env.VUE_APP_BASE_URL}/api/mp/payment/invoice`,
        data,
        { name: 'social.user-subscription' }
      );
    },
    payJob () {
      let data = this.getInvoiceCommonPayloadData();
      data.user_id = this.currentUser.id;
      data.address_id = this.addressSelected.id;
      data.job_id = this.job.id;

      this.sendInvoiceRequest(
        `${process.env.VUE_APP_BASE_URL}/api/mp/payment/invoice`,
        data,
        { name: 'social.user-services' }
      );
    },
    sendInvoiceRequest (url, data, params) {
      this.loading = true;
      axios
        .post(url, data)
        .then((response) => {
          if (response.data.success) {
            this.$swal.fire({
              title: this.$t('awesome'),
              text: this.$t('checkoutCreateSuccessfully'),
              confirmButtonColor: '#19AD93'
            });
            this.sendDataLayerPayment();
            this.$router.push(params);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    payOrder () {
      if (this.verifyCardData() && this.verifyUserData()) {
        if (this.subscription) {
          this.paySubscription();
        } else {
          this.payJob();
        }
      }
    }
  }
};
</script>
